<template>
  <v-col
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
    class="ma-0 pa-0"
  >
    <v-card class="mx-auto"
    >
      <v-card-title class="display-3">{{value.name}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-0 ma-0">
        <v-list>
          <v-list-item-group
            v-model="selected"
            active-class="pink--text"
          >
            <!-- class="listColor" -->
            <template v-for="(item, index) in value.sp_mtmn_document_detail">
              <v-list-item :key="item.skey"
                :class="(index % 2 == 0) ? 'listColor' : 'listColor2'">
                >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title class="display-2" v-text="item.name"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <v-icon color="blue" small class="mr-2" v-bind="attrs" v-on="on" @click="Download(item.path_image, item.filename)">file_download</v-icon> -->
                        <v-btn icon v-bind="attrs" @click="Download(item.path_image, item.filename)">
                          <img src="../assets/SoftwareClinicIcon/Adobe-PDF-Document-icon.png" height="30" weight="30"/>
                        </v-btn>
                      </template>
                      <span>Download</span>
                    </v-tooltip>
                    <!-- <v-btn icon @click="Download(item.path_image, item.filename)">
                      <img src="../assets/SoftwareClinicIcon/Adobe-PDF-Document-icon.png" height="30" weight="30"/>
                    </v-btn> -->
                  </v-list-item-action>
                </template>
              </v-list-item>
              <v-divider
                v-if="index < value.sp_mtmn_document_detail.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
  import Base64Binary from 'base64-arraybuffer'
  export default {
    name: 'FeedCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        selected: [],
      }
    },
    computed: {
      headers () {
        return [
          { text: '', align: 'center', value: 'data-table-select' },
          { text: 'skey', align: 'left', value: 'skey', class: 'text-xl-left body-1 black--text' },
          { text: this.$t('actions'), value: 'actions', sortable: false, class: 'text-xl-left body-1 black--text' },
          { text: this.$t('BranchGSBMaint.name'), value: 'name', align: 'left', class: 'text-xl-left body-1 black--text' },
        ]
      },
    },
    methods: {
      Download (pathImage, filename) {
        this.axios.post(process.env.VUE_APP_API + '/document_detail/getFileBase64', {
          path_image: pathImage
        }, {})
          .then(response => {
            if (response.data.isSuccess) {
              const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.pdf_base64)], {type: 'application/pdf; charset=utf-8'}))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', filename)
              document.body.appendChild(link)
              link.click()
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    },
  }
</script>

<style scoped>
  .v-image__image {
    transition: .3s linear;
  }
  .v-list-item:nth-child(even) {
    background: red;
  }

  .v-list-item-group:nth-child(odd) {
    background: #FFF;
  }
  .listColor {
    background: rgb(242, 245, 247);
  }
  .listColor2 {
    background: #FFF;
  }
  .tile {
    margin: 5px;
    border-radius: 4px;
  }
  .tile:hover {
    background: green;
  }
  .tile:active {
    background: yellow;
  }
</style>
